<template>
  <div>
    <!-- 上半部 -->
    <div class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1">
        <!-- 建立抽獎活動 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('createLottery')"
        >
          建立抽獎活動
        </button>
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <!-- 搜尋條件 -->
        <p>
          搜尋條件:
          <span class="badge bg-primary me-1 mb-1">
            抽獎狀態:{{ recordAdvancedSearchData.singleSelect.lotteryStatus }}
          </span>
        </p>
        <!-- 搜尋區間 -->
        <p v-if="recordAdvancedSearchData.timeRange.switch">
          搜尋區間:
          <span
            v-if="
              recordAdvancedSearchData.timeRange.startTime &&
              recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
              recordAdvancedSearchData.timeRange.endTime
            }}</span
          >
          <span
            v-if="
              recordAdvancedSearchData.timeRange.startTime &&
              !recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
          >
          <span
            v-if="
              !recordAdvancedSearchData.timeRange.startTime &&
              recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
          >
        </p>
      </div>
    </div>
    <!-- dataTable -->
    <div class="tw-container">
      <DataTable
        class="p-datatable-sm"
        :scrollHeight="`${scrollHeight}px`"
        :value="lotteryRecordList"
        dataKey="id"
        :loading="dataTabelLoading"
        :rowHover="true"
        :paginator="true"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows="20"
        :rowsPerPageOptions="[20, 50, 100]"
        :scrollable="true"
        currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
        v-model:filters="searchGlobal"
        filterDisplay="menue"
        stateStorage="local"
        stateKey="dt-state-lotteryRecord-local"
        sortField="id"
        :sortOrder="-1"
      >
        <template #header>
          <div class="d-flex align-items-center">
            <input
              class="form-control me-2"
              v-model="searchGlobal.global.value"
              placeholder="表格搜尋: 編號，主題，描述，開獎狀態"
              style="max-width: 99%"
            />
          </div>
        </template>
        <!-- 操作 -->
        <Column header="" style="min-width: 30px; max-width: 30px">
          <template #body="{ data }">
            <template v-if="!data.draw">
              <div>
                <img
                  title="刪除"
                  class="icon d-inline-block can-click"
                  style="cursor: pointer"
                  src="@/assets/icon/trash-2.png"
                  alt="刪除"
                  @click="showModal('delLottery', data)"
                />
              </div>
            </template>
          </template>
        </Column>
        <!-- 編號 -->
        <Column
          field="id"
          header="編號"
          sortable
          style="min-width: 80px; max-width: 80px"
        >
          <template #body="{ data }">
            <div>
              <i
                v-if="!data.draw"
                class="bi bi-pencil pointer can-click"
                @click="showModal('edit', data)"
              ></i>
              {{ data.id }}
            </div>
          </template>
        </Column>
        <!-- 主題 -->
        <Column
          field="name"
          header="主題"
          sortable
          style="min-width: 200px; max-width: 200px"
        >
          <template #body="{ data }">
            <div class="mb-1">
              <img
                class="icon"
                src="@/assets/icon/facebook-icon.png"
                alt="facebook"
                title="來自facebook的抽獎"
                v-if="data.source === 'FACEBOOK'"
              />
              <img
                class="icon"
                src="@/assets/icon/line.png"
                alt="line"
                title="來自line的抽獎"
                v-else
              />
            </div>
            <a
              class="pointer text-break"
              title="複製抽獎連結"
              @click="copy(data.id)"
              >{{ data.name }}</a
            >
          </template>
        </Column>
        <!-- 描述 -->
        <Column
          field="description"
          header="描述"
          sortable
          style="min-width: 200px"
        >
          <template #body="{ data }">
            <span
              class="pointer can-click text-break overflow-hidden"
              style="
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              "
              @click="showModal('description', data)"
              >{{ data.description ? data.description : "無" }}</span
            >
          </template>
        </Column>
        <!-- 獎項 -->
        <Column header="獎項" style="min-width: 200px">
          <template #body="{ data }">
            <div
              v-if="'storeDrawingPrizes' in data"
              class="pointer can-click"
              @click="showModal('drawingPrizes', data)"
            >
              <!-- 一次只顯示3筆 -->
              <template v-for="i in 3">
                <p class="mb-1" v-if="data.storeDrawingPrizes[i - 1]">
                  {{ i }}.{{ data.storeDrawingPrizes[i - 1].name }}
                  <span v-if="i === 3 && data.storeDrawingPrizes.length > 3"
                    >...</span
                  >
                </p>
              </template>
            </div>
            <!-- loading -->
            <div class="spinner-border spinner-border-sm" role="status" v-else>
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </Column>
        <!-- 人數 -->
        <Column
          field="description"
          header="人數"
          sortable
          style="min-width: 100px; max-width: 100px"
        >
          <template #body="{ data }">
            <span
              class="pointer can-click"
              v-if="'storeDrawingParticipants' in data"
              @click="showModal('drawingParticipant', data)"
            >
              {{ data.storeDrawingParticipants.length }}位
            </span>
            <!-- loading -->
            <div class="spinner-border spinner-border-sm" role="status" v-else>
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </Column>
        <!-- 期間 -->
        <Column
          field="expirationTime"
          header="期間"
          sortable
          style="min-width: 250px"
        >
          <template #body="{ data }">
            <p>
              開始:
              {{
                this.$methods
                  .moment(data.enableTime)
                  .format("YYYY-MM-DD HH:mm:ss")
              }}
            </p>
            <p>
              結束:
              {{
                this.$methods
                  .moment(data.expirationTime)
                  .format("YYYY-MM-DD HH:mm:ss")
              }}
            </p>
          </template>
        </Column>
        <!-- 開獎狀態 -->
        <Column
          field="expirationTime"
          header="開獎狀態"
          sortable
          style="min-width: 150px; max-width: 150px"
        >
          <template #body="{ data }">
            <!-- 未開始 -->
            <template v-if="new Date() * 1 < data.enableTime">
              <p class="fw-bolder text-secondary">未開始</p>
            </template>
            <!-- 進行中 -->
            <template v-else-if="new Date() * 1 < data.expirationTime">
              <p class="fw-bolder text-success">進行中</p>
            </template>
            <!-- 開獎 -->
            <template v-else-if="!data.draw">
              <button
                class="tw-btn tw-btn-danger"
                @click="showModal('drawing', data)"
              >
                開獎
              </button>
            </template>
            <!-- 已開獎 -->
            <template v-else>
              <button
                class="tw-btn tw-btn-secondary"
                @click="showModal('drawing', data)"
              >
                得獎紀錄
              </button>
            </template>
          </template>
        </Column>
        <template #empty>
          <p class="text-center fw-bolder text-primary">
            目前尚未有任何資料 0..0
          </p>
        </template>
      </DataTable>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="抽獎開始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">抽獎開始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="抽獎結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">抽獎結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 建立抽獎活動的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="createLotteryModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">建立抽獎活動</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 抓取FB文章 switch -->
          <!-- <div class="form-check form-switch p-0 mb-2" v-if="!closeFbGroupFun">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="CreateSwitchFbDraw"
              v-model="createLotteryData.fbDraw"
            />
            <label class="form-check-label" for="CreateSwitchFbDraw"
              >抓取FB貼文留言</label
            >
          </div> -->
          <!-- 抓取FB文章說明 -->
          <div
            class="alert alert-primary fw-bolder mb-2"
            v-if="createLotteryData.fbDraw"
          >
            <p class="mb-2">
              1. 系統會依照，「抽獎開始時間」 與
              「抽獎結束時間」，來抓取FB留言。
            </p>
            <p class="mb-2">2. FB文章內容會成為抽獎的描述。</p>
            <p class="mb-2">3. FB文章必須是90天內的文章留言才能正確抓回</p>
            <p>
              4. 只有賣場的顧客才能擁有抽獎資格
              (賣場擁有者、非賣場顧客無法參加抽獎)
            </p>
          </div>
          <!-- 抽獎主題 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateName"
              placeholder="*抽獎主題(25字)"
              maxlength="25"
              v-model="createLotteryData.drawing.name"
            />
            <label for="CreateName" class="text-danger">*抽獎主題(25字)</label>
          </div>
          <!-- 抓取FB文章網址、Keyword -->
          <div v-if="createLotteryData.fbDraw" class="mb-3">
            <!-- fb feed url -->
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="FbFeedUrl"
                placeholder="*FB文章網址"
                maxlength="1000"
                v-model="createLotteryData.feedUrl"
              />
              <label for="FbFeedUrl" class="text-danger">*FB文章網址</label>
            </div>
            <!-- 留言需包含關鍵字 -->
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="FbKeywords"
                maxlength="200"
                v-model="createLotteryData.keywords"
              />
              <label for="FbKeywords"
                >留言需包含關鍵字(非必填，如:易團Go讓團購更容易!)</label
              >
            </div>
          </div>
          <!-- 商品描述 -->
          <div class="form-floating mb-3" v-if="!createLotteryData.fbDraw">
            <textarea
              class="form-control"
              placeholder="抽獎描述(2000字)"
              id="CreateDescription"
              style="height: 300px"
              maxlength="2000"
              v-model="createLotteryData.drawing.description"
            ></textarea>
            <label for="CreateDescription">抽獎描述(2000字)</label>
          </div>
          <!-- 抽獎期間 -->
          <div class="row mb-3">
            <!-- 開始 -->
            <div class="col-6 mb-3 p-0 form-floating">
              <input
                class="form-control"
                type="datetime-local"
                step="1"
                id="CreateEnableTime"
                :min="
                  this.$methods
                    .moment()
                    .add(-90, 'days')
                    .format('YYYY-MM-DD HH:mm:ss')
                "
                placeholder="抽獎開始時間"
                v-model="createLotteryData.drawing.enableTime"
              />
              <label for="CreateEnableTime" class="mb-2 text-danger"
                >*抽獎開始時間</label
              >
            </div>
            <!-- 結束 -->
            <div class="col-6 p-0 form-floating">
              <input
                class="form-control"
                type="datetime-local"
                step="1"
                id="CreateExpirationTime"
                placeholder="抽獎結束時間"
                v-model="createLotteryData.drawing.expirationTime"
              />
              <label for="CreateExpirationTime" class="mb-2 text-danger"
                >*抽獎結束時間</label
              >
            </div>
          </div>

          <!-- 獎項 -->
          <div>
            <p class="tw-border-start fw-bolder mb-2">獎項</p>
            <div class="tw-bg-main mb-3 p-2">
              <!-- 新增 -->
              <div class="d-flex mb-2">
                <!-- 獎項 -->
                <div class="input-group">
                  <input
                    id="CreatePrizeName"
                    class="form-control"
                    type="text"
                    placeholder="用，分隔可做批次新增的動作"
                    v-model="setPrize.name"
                    @keyup.enter="createPrizeInput('create')"
                  />
                </div>
                <!-- 數量 -->
                <div class="">
                  <input
                    onwheel="this.blur()"
                    id="CreatePrizeQuantity"
                    class="form-control"
                    type="number"
                    placeholder="數量"
                    min="1"
                    onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                    v-model="setPrize.quantity"
                    @keyup.enter="createPrizeInput('create')"
                  />
                </div>
                <button
                  class="btn btn-outline-secondary"
                  style="background: white"
                  @click="addComma('CreatePrizeName', 'name')"
                >
                  <img
                    title="逗號"
                    class="icon"
                    src="@/assets/icon/comma.png"
                    alt="，"
                  />
                </button>
                <!-- 新增 -->
                <div class="">
                  <button
                    class="tw-btn tw-btn-success"
                    @click="createPrizeInput('create')"
                    v-if="showAddPrizeBtn"
                  >
                    新增
                  </button>
                </div>
              </div>
              <!-- 獎項清單 -->
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width: 50px">移動</th>
                      <th style="min-width: 100px">獎項</th>
                      <th style="min-width: 100px">數量</th>
                      <th style="max-width: 50px">操作</th>
                    </tr>
                  </thead>
                  <Draggable
                    v-model="createLotteryData.prizes"
                    class="draggable"
                    @start="drag = true"
                    @end="drag = false"
                    item-key="id"
                    tag="tbody"
                    handle=".handle"
                  >
                    <template #item="{ element }">
                      <tr>
                        <td
                          class="text-center pointer handle"
                          style="line-height: 35px"
                        >
                          <i class="bi bi-arrows-move"></i>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="獎項(25字)"
                            maxlength="25"
                            v-model="element.name"
                          />
                        </td>
                        <td>
                          <input
                            onwheel="this.blur()"
                            type="number"
                            class="form-control"
                            placeholder="數量"
                            min="1"
                            onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                            v-model="element.quantity"
                          />
                        </td>
                        <td>
                          <button
                            class="tw-btn tw-btn-danger"
                            @click="delPrizeInput('create', element.id)"
                          >
                            刪除
                          </button>
                        </td>
                      </tr>
                    </template>
                  </Draggable>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createLottery"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除抽獎活動的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delLotteryModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="modal-title border-start ps-2 border-5 border-danger text-danger fw-bold"
          >
            刪除商品
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>確定要 刪除 此次抽獎活動 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="delLottery(this.propsSelectInfos.data[0].id)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯抽獎活動的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editLotteryModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">編輯抽獎活動</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <template v-if="editItem">
            <!-- 抽獎主題 -->
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="EditName"
                placeholder="*抽獎主題(25字)"
                maxlength="25"
                v-model="editItem.name"
              />
              <label for="EditName" class="text-danger">*抽獎主題(25字)</label>
            </div>
            <!-- 抽獎描述 -->
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                placeholder="抽獎描述(2000字)"
                id="EditDescription"
                style="height: 300px"
                maxlength="2000"
                v-model="editItem.description"
              ></textarea>
              <label for="EditDescription">抽獎描述(2000字)</label>
            </div>
            <!-- 抽獎期間 -->
            <!-- <div class="row mb-3">
              <div class="col-6 mb-3 p-0 form-floating">
                <input
                  class="form-control"
                  type="datetime-local"
                  step="1"
                  id="EditEnableTime"
                  placeholder="抽獎開始時間"
                  v-model="editItem.enableTime"
                />
                <label for="EditEnableTime" class="mb-2 text-danger"
                  >*抽獎開始時間</label
                >
              </div>
              <div class="col-6 p-0 form-floating">
                <input
                  class="form-control"
                  type="datetime-local"
                  step="1"
                  id="EditExpirationTime"
                  placeholder="抽獎結束時間"
                  v-model="editItem.expirationTime"
                />
                <label for="EditExpirationTime" class="mb-2 text-danger"
                  >*抽獎結束時間</label
                >
              </div>
            </div> -->
            <!-- 獎項 -->
            <div class="tw-bg-main mb-3 p-2">
              <!-- 新增 -->
              <div class="d-flex mb-2">
                <!-- 獎項 -->
                <div class="input-group">
                  <input
                    id="EditPrizeName"
                    class="form-control"
                    type="text"
                    placeholder="用，分隔可做批次新增的動作"
                    v-model="setPrize.name"
                    @keyup.enter="createPrizeInput('edit')"
                  />
                </div>
                <!-- 數量 -->
                <div class="">
                  <input
                    onwheel="this.blur()"
                    id="EditPrizeQuantity"
                    class="form-control"
                    type="number"
                    placeholder="數量"
                    min="1"
                    v-model="setPrize.quantity"
                    onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                    @keyup.enter="createPrizeInput('edit')"
                  />
                </div>
                <button
                  class="btn btn-outline-secondary"
                  style="background: white"
                  @click="addComma('EditPrizeName', 'name')"
                >
                  <img
                    title="逗號"
                    class="icon"
                    src="@/assets/icon/comma.png"
                    alt="，"
                  />
                </button>
                <!-- 新增 -->
                <div class="">
                  <button
                    class="tw-btn tw-btn-success"
                    @click="createPrizeInput('edit')"
                    v-if="showAddPrizeBtn"
                  >
                    新增
                  </button>
                </div>
              </div>
              <!-- 獎項清單 -->
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <!-- <th style="width: 50px">移動</th> -->
                      <th style="min-width: 100px">獎項</th>
                      <th style="min-width: 100px">數量</th>
                      <th style="max-width: 50px">操作</th>
                    </tr>
                  </thead>
                  <Draggable
                    v-model="editItem.storeDrawingPrizes"
                    class="draggable"
                    @start="drag = true"
                    @end="drag = false"
                    item-key="id"
                    tag="tbody"
                    handle=".handle"
                  >
                    <template #item="{ element }">
                      <tr v-if="!element.deleted">
                        <!-- <td
                          class="text-center pointer handle"
                          style="line-height: 35px"
                        >
                          <i class="bi bi-arrows-move"></i>
                        </td> -->
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="獎項(25字)"
                            maxlength="25"
                            v-model="element.name"
                          />
                        </td>
                        <td>
                          <input
                            onwheel="this.blur()"
                            type="number"
                            class="form-control"
                            placeholder="數量"
                            min="1"
                            onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                            v-model="element.quantity"
                          />
                        </td>
                        <td>
                          <button
                            class="tw-btn tw-btn-danger"
                            @click="delPrizeInput('edit', element.id)"
                          >
                            刪除
                          </button>
                        </td>
                      </tr>
                    </template>
                  </Draggable>
                </table>
              </div>
            </div>
          </template>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="editLottery"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 查看抽獎描述的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="descriptionModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">描述</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="description !== null"
            v-html="description"
            style="height: calc(100vh - 40vh); overflow-y: scroll"
          ></div>
          <p v-else class="fw-bolder text-danger text-center">無活動描述</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="description = null"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 查看抽獎獎項的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="drawingPrizesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">獎項</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 表格 -->
          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="float-header">
                <tr>
                  <th>獎項</th>
                  <th>數量</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(drawingPrize, key) in drawingPrizes"
                  :key="drawingPrize.id"
                >
                  <td>{{ drawingPrize.name }}</td>
                  <td>{{ drawingPrize.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="drawingPrizes = null"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 查看參加人數的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="drawingParticipantModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">參加抽獎名單</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 表格 -->
          <div
            class="table-responsive"
            style="height: calc(100vh - 40vh); overflow-y: scroll"
          >
            <table class="table table-hover">
              <thead class="float-header">
                <tr>
                  <th style="min-width: 100px">大頭照</th>
                  <th style="min-width: 200px">顧客</th>
                  <th style="min-width: 245px">參加時間</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="participants in storeDrawingParticipants"
                  :key="participants.participantId"
                >
                  <!-- 大頭照 -->
                  <td>
                    <div
                      class="d-flex justify-content-center"
                      v-if="
                        participants.lineProfilePicture ||
                        participants.fbProfilePicture
                      "
                    >
                      <Image
                        class="table-img border border-primary border-3 me-2"
                        :url="participants.fbProfilePicture"
                        v-if="participants.fbProfilePicture"
                        :alt="participants.fbProfilePicture"
                      ></Image>
                      <Image
                        class="table-img border border-primary border-3 me-2"
                        :url="participants.lineProfilePicture"
                        v-if="participants.lineProfilePicture"
                        :alt="participants.lineProfilePicture"
                      ></Image>
                    </div>
                    <img
                      class="table-img"
                      v-else
                      src="@/assets/other-images/nobody.png"
                      alt=""
                    />
                  </td>
                  <!-- 顧客 -->
                  <td>
                    <div class="mb-1">
                      顧客ID：{{ participants.participantId }}
                    </div>
                    <div class="mb-1">
                      <span title="顧客名稱">{{ participants.name }}</span>
                      <span
                        class="text-success"
                        title="顧客暱稱"
                        v-if="participants.nickName"
                      >
                        | {{ participants.nickName }}</span
                      >
                    </div>
                    <div class="mb-1" v-if="participants.userFacebookName">
                      <p class="badge bg-primary">
                        FB：{{ participants.userFacebookName }}
                      </p>
                    </div>
                    <div class="mb-1" v-if="participants.userLineName">
                      <p class="badge bg-success">
                        LINE：{{ participants.userLineName }}
                      </p>
                    </div>
                  </td>
                  <!-- 參加時間 -->
                  <td>{{ participants.joinDrawTime }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 抽獎/抽獎紀錄的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="drawingModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            <template v-if="!drawing.data.draw">抽獎</template>
            <template v-else>得獎紀錄</template>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="!drawing.data.draw"
            class="alert alert-danger"
            role="alert"
          >
            <p class="mb-2">
              1. 抽獎只能抽一次，抽完獎後系統將會自動儲存得獎結果
            </p>
            <p>2. 開獎後，將無法再對抽獎活動進行(編輯/刪除)</p>
          </div>
          <!-- 得獎紀錄 -->
          <div
            class="table-responsive"
            style="height: calc(100vh - 40vh); overflow-y: scroll"
            v-if="drawing.data.draw"
          >
            <table class="table" :id="`drawTable_${drawing.data.id}`">
              <thead>
                <tr>
                  <th style="min-width: 150px; max-width: 150px">獎項</th>
                  <th style="min-width: 245px">得獎者</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(drawWinner, drawKey) in drawWinners"
                  :key="drawWinner.id"
                >
                  <tr v-for="(quantity, key) in drawWinner.quantity" :key="key">
                    <!-- 獎項 -->
                    <td
                      style="
                        background: #fdf7dd;
                        min-width: 150px;
                        max-width: 150px;
                      "
                      :style="drawKey === 0 ? 'border-top-width: 2px;' : ''"
                      v-if="key === 0"
                      :rowspan="drawWinner.quantity"
                    >
                      {{ drawWinner.prizeName }}
                    </td>
                    <!-- 得獎者 -->
                    <td>
                      <div class="row text-start" v-if="drawWinner.winner[key]">
                        <!-- 編號 -->
                        <div class="col-1 d-flex align-items-center">
                          {{ key + 1 }}.
                        </div>
                        <!-- 大頭照 -->
                        <div class="col-2">
                          <template
                            v-if="
                              drawWinner.winner[key].lineProfilePicture ||
                              drawWinner.winner[key].fbProfilePicture
                            "
                          >
                            <Image
                              class="table-img border border-primary border-3 me-2"
                              :url="drawWinner.winner[key].fbProfilePicture"
                              v-if="drawWinner.winner[key].fbProfilePicture"
                              :alt="drawWinner.winner[key].fbProfilePicture"
                            ></Image>
                            <Image
                              class="table-img border border-primary border-3"
                              :url="drawWinner.winner[key].lineProfilePicture"
                              v-if="drawWinner.winner[key].lineProfilePicture"
                              :alt="drawWinner.winner[key].lineProfilePicture"
                            ></Image>
                          </template>
                          <img
                            class="table-img"
                            v-else
                            src="@/assets/other-images/nobody.png"
                            alt=""
                          />
                        </div>
                        <!-- 顧客 -->
                        <div class="col-9">
                          <div class="mb-1">
                            <a
                              :href="`/seller/store/${storeId}/participant/${drawWinner.winner[key].participantId}/merchOrder`"
                              target="_blank"
                              >顧客ID：{{
                                drawWinner.winner[key].participantId
                              }}</a
                            >
                          </div>
                          <div class="mb-1">
                            <span title="顧客名稱">{{
                              drawWinner.winner[key].name
                            }}</span>
                            <span
                              class="text-success"
                              title="顧客暱稱"
                              v-if="drawWinner.winner[key].nickName"
                            >
                              | {{ drawWinner.winner[key].nickName }}</span
                            >
                          </div>
                          <div
                            class="mb-1"
                            v-if="drawWinner.winner[key].userFacebookName"
                          >
                            <p class="badge bg-primary">
                              FB：{{ drawWinner.winner[key].userFacebookName }}
                            </p>
                          </div>
                          <div
                            class="mb-1"
                            v-if="drawWinner.winner[key].userLineName"
                          >
                            <p class="badge bg-success">
                              LINE：{{ drawWinner.winner[key].userLineName }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <span class="col-1">{{ key + 1 }}.</span>
                        <span class="col-11">無</span>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!-- 得獎者是否能重複 -->
          <div class="form-check" v-if="!drawing.data.draw">
            <input
              class="form-check-input"
              type="checkbox"
              id="drawingRepeat"
              v-model="drawing.repeat"
            />
            <label class="form-check-label mt-1" for="drawingRepeat">
              得獎者是否能重複獲獎
            </label>
          </div>
        </div>
        <div class="modal-footer" :class="!drawing.data.draw ? 'd-block' : ''">
          <!-- 抽獎按鈕 -->
          <div class="d-grid gap-2" v-if="!drawing.data.draw">
            <button
              class="tw-btn tw-btn-success tw-text-size24 tw-btn-hover"
              type="button"
              @click="draw()"
            >
              抽獎
            </button>
          </div>
          <template v-else>
            <!-- 關閉 -->
            <button
              type="button"
              class="tw-btn tw-btn-secondary"
              data-bs-dismiss="modal"
            >
              關閉
            </button>
            <!-- 截圖並下載 -->
            <button
              class="tw-btn tw-btn-success"
              @click="shot(`drawTable_${drawing.data.id}`)"
            >
              截圖並下載
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import Row from "primevue/row"; //optional for row
import { FilterMatchMode } from "primevue/api";
// draggable
import Draggable from "vuedraggable";

// shot
import html2canvas from "html2canvas";

export default {
  components: { DataTable, Column, ColumnGroup, Row, FilterMatchMode, Draggable},
  data() {
    return {
      // modal
      advancedSearchModal: {},
      createLotteryModal: {},
      delLotteryModal: {},
      editLotteryModal: {},
      descriptionModal: {},
      drawingPrizesModal: {},
      drawingParticipantModal: {},
      drawingModal: {},
      // data
      serverToken: "",
      storeId: 0,
      participantList: {
        load: true,
        data: [],
      },
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: true,
      lotteryRecordList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      propsSelectInfos: {
        show: "name",
        data: [],
      },
      // 建立抽獎活動
      showAddPrizeBtn: true,
      createLotteryData: {
        fbDraw: false,
        feedUrl: "",
        keywords: "",
        drawing: {
          storeId: 0,
          name: "",
          description: "",
          enableTime: "",
          expirationTime: "",
        },
        prizes: [{ name: "獎項一", quantity: 1 }],
      },
      setPrize: {
        id: "",
        name: "",
        quantity: "",
      },
      // 編輯抽獎
      editItem: null,
      // 抽獎描述
      description: null,
      // 抽獎獎項
      drawingPrizes: null,
      // 抽獎參與者
      storeDrawingParticipants: [],
      // 抽獎
      drawing: {
        data: { draw: false },
        repeat: false,
      },
      // 得獎名單
      drawWinners: [],
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: false,
          startTime: null,
          endTime: null,
        },
        singleSelect: {
          lotteryStatus: "未完成",
        },
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    };
  },
  created() {
    this.initialization()
    this.getParticipantList() 
    this.advancedSearch()
  },
  mounted() {
    this.createModals([
      "advancedSearchModal", "createLotteryModal", "delLotteryModal", "editLotteryModal", "descriptionModal",
      "drawingPrizesModal", "drawingParticipantModal", "drawingModal",
    ]);
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem("dt-state-lotteryRecord-local");
    this.$methods.breakOff();
  },
  computed: {
    // 判斷時間，若時間已超過 2024/4/22 就關閉所有有關 FB 社團功能
    closeFbGroupFun() {
      return new Date() > new Date("2024/04/22 00:00:01");
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie("serverToken")
      this.storeId = this.$route.params.storeId
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem("dt-state-lotteryRecord-local")
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: "lotteryStatus",
          key: "抽獎狀態",
          value: [
            {
              id: "A1",
              value: "未完成",
            },
            {
              id: "A2",
              value: "已完成",
            },
            {
              id: "A3",
              value: "全部",
            },
          ],
        },
      ];
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(
        JSON.stringify(this.advancedSearchData)
      );
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === "advancedSearch") {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(
          JSON.stringify(this.recordAdvancedSearchData)
        );
        this.advancedSearchModal.show();
      } else if (status === "createLottery") {
        // 建立抽獎活動
        this.createLotteryData = {
          fbDraw: false,
          feedUrl: "",
          keywords: "",
          drawing: {
            storeId: 0,
            name: "",
            description: "",
            enableTime: "",
            expirationTime: "",
          },
          prizes: [
            { id: `tmp_${new Date() * 1}`, name: "獎項一", quantity: 1 },
          ],
        };
        this.setPrize = {
          id: "",
          name: "",
          quantity: "",
        };
        this.createLotteryModal.show();
      } else if (status === "delLottery") {
        // 刪除抽獎活動
        this.propsSelectInfos.data = [item];
        this.delLotteryModal.show();
      } else if (status === "edit") {
        // 編輯抽獎活動
        this.editItem = JSON.parse(JSON.stringify(item));
        this.editItem.enableTime = this.$methods
          .moment(this.editItem.enableTime)
          .format("YYYY-MM-DD HH:mm:ss");
        this.editItem.expirationTime = this.$methods
          .moment(this.editItem.expirationTime)
          .format("YYYY-MM-DD HH:mm:ss");
        this.editLotteryModal.show();
        this.setPrize = {
          id: "",
          name: "",
          quantity: "",
        };
      } else if (status === "description") {
        this.description = null;
        // 展示描述
        if (item.description)
          this.description = item.description.replace(/(\r\n)|(\n)/g, "<br>");
        this.descriptionModal.show();
      } else if (status === "drawingPrizes") {
        // 展示獎項
        this.drawingPrizes = item.storeDrawingPrizes;
        this.drawingPrizesModal.show();
      } else if (status === "drawingParticipant") {
        // 展示抽獎參與人數
        this.drawingParticipantsMap(item)
        this.drawingParticipantModal.show();
      } else if (status === "drawing") {
        if (item.storeDrawingParticipants.length === 0) return this.SweetAlert("other", "無人參與抽獎")
        this.drawWinners = []
        if (item.draw) {
          // 若有抽獎紀錄，秀得獎者名單
          this.drawWinnersMap(item)
        } 
        this.drawing = {
          data: item,
          repeat: false,
        }

        this.drawingModal.show();
      }
    },
    // 取賣場顧客 (Api)
    getParticipantList() {
      const query = {columns:[{columnName:"id",siteObjectType:13}],distinct:true,group:{groups:[{column:{columnName:"StoreId",siteObjectType:13},operator:{operator:"=",type:1,value:this.storeId},},],operator:1,},siteObjectType:13,sqlCommandType:1}
      const vm = this
      const getParticipantList = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken,
      };
      const data = [
        {
          query: query,
          methods: "{getUser{getUserLineLink,getUserFacebookLink}}",
        },
      ];
      this.participantList = [];
      $.ajax({
        type: "POST",
        async: true,
        url: getParticipantList,
        data: JSON.stringify(data),
        headers: header,
        contentType: "application/json",
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            vm.participantList = {
              load: false,
              data: res.data[0].objects,
            };
          }
        },
        error: function (err) {
          console.log(err.responseJSON);
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message);
          vm.$methods.switchLoading("hide");
          this.dataTabelLoading = false;
        },
      });
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      this.dataTabelLoading = true;
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      let query = {columns:[{columnName:"id",siteObjectType:67}],distinct:true,group:{groups:[{column:{columnName:"storeId",siteObjectType:67},operator:{operator:"=",type:1,value:this.storeId},},],operator:1,},joins:[],siteObjectType:67,sqlCommandType:1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
              column: {
                columnName: 'enableTime',
                siteObjectType: 67
              },
              operator: {
                operator: ">=",
                type: 1,
                value: {
                  name: `from_unixtime(${startTime})`
                }
              }
            })
        }
        if (this.advancedSearchData.timeRange.endTime) {
            const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
            query.group.groups.push({
              column: {
                columnName: 'expirationTime',
                siteObjectType: 67
              },
              operator: {
                operator: "<=",
                type: 1,
                value: {
                  name: `from_unixtime(${endTime})`
                }
              }
            })
          }
      }
      this.getStoreDrawing(query);
      if (closeModal) this.advancedSearchModal.hide()
    },
    // 取得抽獎列表 (Api)
    getStoreDrawing(query) {
      const vm = this;
      const getStoreDrawingApi = `${process.env.VUE_APP_API}/search/getSiteObjects`;
      const header = {
        authorization: this.serverToken,
      };
      const data = [
        {
          query: query,
          methods: "{isDraw}",
        },
      ];
      $.ajax({
        type: "POST",
        async: true,
        url: getStoreDrawingApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: "application/json",
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            const data = res.data[0] ? res.data[0].objects : []

            const storeDrawingList = {
              type: "create",
              data: data,
            }

            vm.lotteryRecordMap(storeDrawingList);
            const ids = vm.lotteryRecordList.map((obj) => obj.id);
            // 取深層資料 (獎項、參與者)
            vm.getDeepStoreDrawing(ids)
          }
        },
        error: function (err) {
          console.log(err.responseJSON);
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message);
          vm.$methods.switchLoading("hide");
          this.dataTabelLoading = false;
        },
      });
    },
    // 取得抽獎列表深層資料
    // (抽獎ID, 要取甚麼 {getStoreDrawingPrizes獎項、getStoreDrawingPrizes{getStoreDrawingPrizeParticipantLinks}中獎者、getStoreDrawingParticipants參與者})
    getDeepStoreDrawing(storeDrawingIds) {
      const vm = this;
      const getStoreDrawingApi = `${process.env.VUE_APP_API}/search/getSiteObjects`;
      const header = {
        authorization: this.serverToken,
      };
      let query = {columns:[{columnName:"id",siteObjectType:67}],distinct:true,group:{groups:[{column:{columnName:"storeId",siteObjectType:67},operator:{operator:"=",type:1,value:this.storeId},},{column:{columnName:"id",siteObjectType:67},operator:{type:3,value:storeDrawingIds},},],operator:1,},joins:[],siteObjectType:67,sqlCommandType:1}
      const data = [
        {
          query: query,
          methods: "{isDraw,getStoreDrawingPrizes{getStoreDrawingPrizeParticipantLinks},getStoreDrawingParticipants}",
        },
      ];
      $.ajax({
        type: "POST",
        async: true,
        url: getStoreDrawingApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: "application/json",
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            const deepStoreDrawingList = {
              type: "change",
              data: res.data[0] ? res.data[0].objects : [],
            };
            vm.lotteryRecordMap(deepStoreDrawingList);
          }
        },
        error: function (err) {
          console.log(err.responseJSON);
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message);
          vm.$methods.switchLoading("hide");
          this.dataTabelLoading = false;
        },
      });
    },
    // 整理抽獎列表 (本地)
    lotteryRecordMap(lotteryRecord, paging) {
      console.log(lotteryRecord);
      if (lotteryRecord.type === "create") {
        this.lotteryRecordList = [];
        lotteryRecord.data.forEach((item) => {
          const object = {
            class: item.class,
            createTime: item.createTime,
            description: item.description,
            draw: item.draw,
            enableTime: item.enableTime,
            expirationTime: item.expirationTime,
            id: item.id,
            storeId: item.storeId,
            name: item.name,
            source: item.source
          };

          // 進階搜尋 > 抽獎狀態
          let lotteryStatus = false
          switch (this.recordAdvancedSearchData.singleSelect.lotteryStatus) {
            case '未完成':
              if (!item.draw) lotteryStatus = true
              break;
            case '已完成':
              if (item.draw) lotteryStatus = true
              break;
            case '全部':
              lotteryStatus = true
              break;
          }

          if (lotteryStatus) this.lotteryRecordList.push(object)
        });
        this.dataTabelLoading = false;
        console.log('抽獎資料' ,this.lotteryRecordList)
      } else if (lotteryRecord.type === "change") {
        // 單一資料更改
        // 找尋要更改的資料 > 判斷資料哪個值有變 > 僅更改有變的值
        lotteryRecord.data.forEach((newStoreDrawing) => {
          this.lotteryRecordList.some((oldStoreDrawing) => {
            if (newStoreDrawing.id === oldStoreDrawing.id) {
              Object.keys(newStoreDrawing).forEach((objKey) => {
                if (oldStoreDrawing[objKey] !== newStoreDrawing[objKey]) {
                  oldStoreDrawing[objKey] = newStoreDrawing[objKey];
                }
              });
              return true
            }
          });
        });
        console.log(this.lotteryRecordList);
      }
    },
    // 複製 (本地)
    copy(drawId) {
      let createInput = document.createElement("input"); // 建立一個隱藏input（重要！）
      createInput.value = `${process.env.VUE_APP_ORIGINURL}/customer/store/${this.storeId}/lottery?drawingId=${drawId}`;
      createInput.id = "copy";
      document.body.appendChild(createInput);
      createInput.select(); // 選擇物件
      document.execCommand("Cut"); // 執行瀏覽器複製命令
      document.getElementById("copy").remove();
      // 組SweetAlertTitle
      const SweetAlertTitle = {
        icon: "success",
        title: {
          show: true,
          value: "複製成功",
        },
        text: {
          show: false,
          value: "",
        },
        timer: 1000,
      };
      this.SweetAlert("setByMyself", SweetAlertTitle);
    },
    // 加逗號 (本地)
    addComma(where, recordString) {
      try {
        const el = document.querySelector(`#${where}`);
        const endCaret = el.selectionEnd; // 結束游標位置
        this.setPrize[recordString] =
          this.setPrize[recordString].substring(0, endCaret) +
          "，" +
          this.setPrize[recordString].substring(endCaret);
      } catch (error) {
        console.log(error);
        alert("您所在的瀏覽器或您的裝置不支援此功能");
      }
    },
    // 截圖 (本地)
    shot(drawId) {
      console.log('截圖')
      html2canvas(document.getElementById(drawId), {
        useCORS: true,
      }).then(canvas => {
        let alink = document.createElement("a")
        alink.href = canvas.toDataURL("image/png")
        console.log(alink.href)
        alink.download = `抽獎活動${drawId.split('_')[1]}_開獎結果`
        alink.click()
      })
    },
    // 建立create/編輯edit 抽獎活動 > 新增獎項 (本地)
    createPrizeInput(status) {
      // 每次點擊都讓按鈕消失 500ms，這樣獎項內的 id 才不會有重複的可能
      const vm = this;
      this.showAddPrizeBtn = false;
      setTimeout(() => {
        vm.showAddPrizeBtn = true;
      }, 500);

      if (!this.setPrize.name) return this.SweetAlert("other", "您尚未輸入任何東西");
      let prize = this.setPrize.name.split("，");
      const error = prize.some((item) => {
        return item.length > 25;
      });
      if (error)
        return this.SweetAlert("other", "您所輸入的款式中，其中有超過25字");
      prize.forEach((item, index) => {
        if (item) {
          if (status === "create") {
            this.createLotteryData.prizes.push({
              id: `tmp${new Date() * 1 * (index + 1)}`,
              name: item,
              quantity: this.setPrize.quantity
                ? this.setPrize.quantity
                : 1,
            });
          } else if (status === "edit") {
            this.editItem.storeDrawingPrizes.push({
              id: `tmp${new Date() * 1 * (index + 1)}`,
              name: item,
              quantity: this.setPrize.quantity
                ? this.setPrize.quantity
                : 1,
              deleted: false,
            });
          }
        }
      });
      this.setPrize = {
        name: "",
        quantity: "",
      };
    },
    // 建立create/編輯edit 抽獎活動 > 刪除獎項 (本地)
    delPrizeInput(status, id) {
      if (status === "create") {
        if (this.createLotteryData.prizes.length === 1)
          return this.SweetAlert("other", "至少要有一筆獎項");
        this.createLotteryData.prizes.some((item, index) => {
          if (item.id === id) {
            this.createLotteryData.prizes.splice(index, 1);
            return true;
          }
        });
      } else if (status === "edit") {
        let haveDrawingPrizes = 0;
        this.editItem.storeDrawingPrizes.forEach((item) => {
          if (!item.deleted) haveDrawingPrizes++;
        });
        if (haveDrawingPrizes === 1)
          return this.SweetAlert("other", "至少要有一筆獎項");
        this.editItem.storeDrawingPrizes.some((item, index) => {
          if (item.id === id) {
            // item.id 類型為數值 = 原資料 , 字串為 = 新增資料
            if (typeof item.id === "string")
              this.editItem.storeDrawingPrizes.splice(index, 1);
            else {
              item.deleted = true;
            }
            console.log(item);
            return true;
          }
        });
      }
    },
    // 建立抽獎活動 (Api)
    createLottery() {
      if (!this.createLotteryData.drawing.name) return this.SweetAlert("other", "請輸入抽獎主題")
      if (!this.createLotteryData.drawing.enableTime) return this.SweetAlert("other", "請選擇抽獎開始時間")
      if (!this.createLotteryData.drawing.expirationTime) return this.SweetAlert("other", "請選擇抽獎結束時間")
      if (this.createLotteryData.drawing.enableTime > this.createLotteryData.drawing.expirationTime) return this.SweetAlert("other", "抽獎「結束」時間 必須晚於 「開始」時間")
      if (this.createLotteryData.fbDraw && !this.createLotteryData.feedUrl) return this.SweetAlert("other", "請輸入FB貼文網址");
      this.$methods.switchLoading("show");
      const vm = this;
      const header = {
        authorization: this.serverToken,
      };
      let createLotteryApi;
      let data = [{}];

      if (this.createLotteryData.fbDraw) {
        // 抓取FB留言
        createLotteryApi = `${process.env.VUE_APP_API}/apps/fb/draw`;
        data = [
          {
            feedUrl: this.createLotteryData.feedUrl,
            keywords: this.createLotteryData.keywords ? [this.createLotteryData.keywords] : null,
            repeat: false,
            draw: false, // 先不要抽獎
          },
        ];
      } else {
        // 一般抽獎
        createLotteryApi = `${process.env.VUE_APP_API}/storeDrawing/create`;
      }
      data[0].drawing = {
        storeId: this.storeId,
        name: this.createLotteryData.drawing.name,
        description: this.createLotteryData.drawing.description,
        enableTime: new Date(this.createLotteryData.drawing.enableTime) * 1,
        expirationTime: new Date(this.createLotteryData.drawing.expirationTime) * 1,
        source: this.createLotteryData.fbDraw ? 'facebook' : 'line'
      }

      data[0].prizes = this.createLotteryData.prizes.map((item) => ({
        name: item.name,
        quantity: item.quantity,
      }));

      console.log(data);
      $.ajax({
        type: "POST",
        async: true,
        url: createLotteryApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: "application/json",
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            vm.SweetAlert(res.code, res.message);
            vm.createLotteryModal.hide();
            vm.advancedSearch();
          } else {
            vm.SweetAlert(res.code, res.message);
          }
          vm.$methods.switchLoading("hide");
        },
        error: function (err) {
          console.log(err.responseJSON);
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message);
          vm.$methods.switchLoading("hide");
        },
      });
    },
    // 刪除抽獎活動 (Api)
    delLottery(id) {
      this.$methods.switchLoading("show");
      const vm = this;
      const delLotteryApi = `${process.env.VUE_APP_API}/storeDrawing/delete`;
      const header = {
        authorization: this.serverToken,
      };
      const data = new FormData();
      data.append("ids", [id]);
      $.ajax({
        type: "DELETE",
        async: true,
        url: delLotteryApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            vm.advancedSearch();
            vm.delLotteryModal.hide();
          } else {
            vm.SweetAlert(res.code, res.message);
          }
          vm.$methods.switchLoading("hide");
        },
        error: function (err) {
          console.log(err.responseJSON);
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message);
          vm.$methods.switchLoading("hide");
        },
      });
    },
    // 編輯抽獎活動 (Api)
    editLottery() {
      if (!this.editItem.name) return this.SweetAlert("other", "請輸入抽獎主題")
      if (!this.editItem.enableTime) return this.SweetAlert("other", "請選擇抽獎開始時間")
      if (!this.editItem.expirationTime) return this.SweetAlert("other", "請選擇抽獎結束時間")
      if (this.editItem.enableTime > this.editItem.expirationTime) return this.SweetAlert("other", "抽獎「結束」時間 必須晚於 「開始」時間")
      this.$methods.switchLoading("show");
      const vm = this
      const editLotteryApi = `${process.env.VUE_APP_API}/storeDrawing/update`
      const header = {
        authorization: this.serverToken,
      }
      const prizes = this.editItem.storeDrawingPrizes.map((item) => {
        const obj = {
          name: item.name,
          quantity: item.quantity,
          deleted: item.deleted ? item.deleted : false,
        }
        if (typeof item.id === "number") obj.id = item.id
        return obj
      })
      const data = [
        {
          drawing: {
            id: this.editItem.id,
            name: this.editItem.name,
            description: this.editItem.description,
            enableTime: new Date(this.editItem.enableTime) * 1,
            expirationTime: new Date(this.editItem.expirationTime) * 1,
            source: this.editItem.source.toLowerCase()
          },
          prizes: prizes,
        },
      ]
      console.log(data)
      $.ajax({
        type: "PUT",
        async: true,
        url: editLotteryApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: "application/json",
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            vm.SweetAlert(res.code, res.message)
            vm.editLotteryModal.hide()
            vm.getDeepStoreDrawing([vm.editItem.id])
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading("hide")
        },
        error: function (err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading("hide")
        },
      });
    },
    // 抽獎 (Api)
    draw() {
      const vm = this;
      const drawApi = `${process.env.VUE_APP_API}/storeDrawing/draw`
      const header = {
        authorization: this.serverToken,
      }
      console.log(this.drawing);
      const data = [
        {
          id: this.drawing.data.id,
          repeat: this.drawing.repeat,
        },
      ]
      $.ajax({
        type: "POST",
        async: true,
        url: drawApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: "application/json",
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            vm.SweetAlert(res.code, res.message);
            vm.getDeepStoreDrawing([vm.drawing.data.id])
            vm.drawingModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading("hide")
        },
        error: function (err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading("hide")
        },
      })
    },
    // 整理抽獎參與人數 (本地)
    drawingParticipantsMap(data) {
      const storeDrawingParticipants = data.storeDrawingParticipants;
      this.storeDrawingParticipants = []
      storeDrawingParticipants.forEach((drawingParticipant) => {
          this.participantList.data.some((participant) => {
            if (drawingParticipant.participantId === participant.id) {
              const obj = {
                drawingParticipantId: drawingParticipant.id,
                participantId: participant.id,
                name: participant.user.name,
                nickName: participant.nickName,
                userFacebookName: participant.user.userFacebookLink.appUserId
                  ? participant.user.userFacebookLink.name
                  : null,
                userLineName: participant.user.userLineLink.appUserId
                  ? participant.user.userLineLink.name
                  : null,
                lineProfilePicture: null,
                fbProfilePicture: null,
                joinDrawTime: this.$methods
                  .moment(drawingParticipant.createTime)
                  .format("YYYY-MM-DD HH:mm:ss"),
              };
              // 大頭貼處理
              if (
                participant.user.userLineLink &&
                participant.user.userLineLink.profilePicture
              )
                obj.lineProfilePicture =
                  participant.user.userLineLink.profilePicture;
              if (
                participant.user.userFacebookLink &&
                participant.user.userFacebookLink.profilePicture
              )
                obj.fbProfilePicture =
                  participant.user.userFacebookLink.profilePicture;
              this.storeDrawingParticipants.push(obj);
            }
          });
        });
    },
    // 整理得獎名單 (本地)
    drawWinnersMap(data) {
      this.drawingParticipantsMap(data) // 取抽獎參與人數名單
      console.log("參與者:", this.storeDrawingParticipants) // 參與者
      const drawWinners = []
      data.storeDrawingPrizes.forEach(prize => {
        console.log('獎項:', prize)
        const obj = { 
          id: prize.id,
          prizeName: prize.name, 
          quantity: prize.quantity,
          winner: []
        }
        // 抓取得獎者資料
        const winnerIds = prize.storeDrawingPrizeParticipantLinks.map(obj => (obj.storeDrawingParticipantId))
        winnerIds.forEach(winnerId => {
          this.storeDrawingParticipants.some(Participant => {
            if (Participant.drawingParticipantId === winnerId) {
              obj.winner.push(Participant)
              return true
            }
          })
        })
        drawWinners.push(obj)
      })
      this.drawWinners = drawWinners
      console.log("得獎名單:", drawWinners)
    },
  },
}
</script>
